import React, { ReactNode, useEffect, useState } from 'react';
import type { Swiper as TSwiper } from 'swiper/types';
import Image, { ImageProps } from 'next/image';

import { MainSlider } from './components/MainSlider';
import { TabsSlider } from './components/TabsSlider';
import clsx from 'clsx';
import { Navigation } from './components/Navigation';
import { useDirection } from '../../../hooks';

export enum EGalleryTheme {
  Theme1 = 'Theme1',
  Theme2 = 'Theme2',
}

export enum EGalleryGap {
  Gap32 = 'Gap32',
  Gap72 = 'Gap72',
}

export type TGalleryProps = {
  title?: React.JSX.Element | string;
  titleIcons?: (ImageProps | null)[];
  tabs?: {
    title?: React.JSX.Element | string;
    description?: React.JSX.Element | string;
    imageDesktop?: ImageProps;
    imageTablet?: ImageProps;
    imageMobile?: ImageProps;
  }[];
  theme?: EGalleryTheme;
  children?: ReactNode;
  nowrapForTabs?: boolean;
  gradientColorFrom?: string;
  gradientColorTo?: string;
  navigationClass?: string;
  gap?: EGalleryGap;
};

export const Gallery: React.FC<TGalleryProps> = ({
  title,
  titleIcons = [],
  tabs,
  theme = EGalleryTheme.Theme1,
  children,
  nowrapForTabs = true,
  navigationClass,
  gradientColorFrom,
  gradientColorTo,
  gap = EGalleryGap.Gap32,
}) => {
  if (!tabs?.length) {
    return null;
  }
  const [thumbsSwiper, setThumbsSwiper] = useState<TSwiper>();
  const [thumbsReady, setThumbsReady] = useState(false);
  const [mainSwiper, setMainSwiper] = useState<TSwiper>();
  const tabsSliderClass = clsx({
    'tablet:max-w-[12.75rem]': theme === EGalleryTheme.Theme2,
  });
  const tabsWrapperClass = clsx('max-w-[calc(100%-152px)]', {
    'tablet:max-w-[calc(100%-200px)] grow': theme === EGalleryTheme.Theme1,
    'tablet:max-w-none tablet:w-[204px]': theme === EGalleryTheme.Theme2,
  });
  const direction = useDirection();
  useEffect(() => {
    setThumbsReady(!!thumbsSwiper && !thumbsSwiper.destroyed);
  }, [thumbsSwiper]);
  useEffect(() => {
    if (thumbsSwiper && direction) {
      thumbsSwiper.changeLanguageDirection(direction);
    }
  }, [direction]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (mainSwiper) {
          const wrapper = mainSwiper.thumbs?.swiper?.wrapperEl;
          const classList = [
            '[&_[data-active]]:duration-0',
            '[&_[data-active]]:-translate-x-1/2',
            '[&_[data-active]]:rtl:translate-x-1/2',
          ];
          if (entry.isIntersecting) {
            mainSwiper.autoplay?.start();
            wrapper?.classList.remove(...classList);
          } else {
            mainSwiper.autoplay?.stop();
            const wrapper = mainSwiper.thumbs?.swiper?.wrapperEl;
            wrapper?.classList.add(...classList);
          }
        }
      },
      { threshold: 0.6 },
    );

    if (mainSwiper?.el) {
      observer.observe(mainSwiper.el);
    }

    return () => {
      if (mainSwiper?.el) {
        observer.unobserve(mainSwiper.el);
      }
    };
  }, [mainSwiper, theme]);
  return (
    <div
      className={clsx(
        'mx-auto w-full max-w-[462px] px-4',
        theme === EGalleryTheme.Theme1
          ? 'tablet:max-w-[79rem]'
          : 'shrink-0 grow-0 tablet:max-w-[53rem]',
      )}
    >
      {title ? (
        <h2 className='mb-8 flex flex-col items-start gap-4 text-5xl font-semibold leading-loose tablet:flex-row tablet:items-end tablet:text-8xl tablet:leading-snug'>
          {title}
          {titleIcons.length ? (
            <span className='flex gap-2 desktop:pb-2'>
              {titleIcons.map((icon, index) =>
                icon ? (
                  <Image key={index} className='size-10' {...icon} />
                ) : null,
              )}
            </span>
          ) : null}
        </h2>
      ) : null}
      <div
        className={clsx('flex flex-col gap-8', {
          'tablet:flex-row-reverse': theme !== EGalleryTheme.Theme1,
          'tablet:gap-[4.5rem]': gap === EGalleryGap.Gap72,
        })}
      >
        <div
          className={clsx('flex items-end gap-4', {
            'tablet:gap-16': theme === EGalleryTheme.Theme1,
            'tablet:flex-col tablet:items-start tablet:gap-6':
              theme === EGalleryTheme.Theme2,
          })}
        >
          <TabsSlider
            wrapperClass={tabsWrapperClass}
            theme={theme}
            className={tabsSliderClass}
            tabs={tabs}
            onSwiper={setThumbsSwiper}
            hasNowrap={nowrapForTabs}
            gradientColorFrom={gradientColorFrom}
            gradientColorTo={gradientColorTo}
          />
          <Navigation
            swiper={mainSwiper}
            buttonClass={navigationClass}
            className={clsx({
              'tablet:hidden': theme === EGalleryTheme.Theme2,
            })}
          />
        </div>
        <div
          className={clsx({
            'tablet:w-[calc(100%-238px)]': theme === EGalleryTheme.Theme2,
          })}
        >
          {thumbsReady ? (
            <MainSlider
              onSwiper={setMainSwiper}
              theme={theme}
              tabs={tabs}
              thumbsSwiper={thumbsSwiper}
            >
              {children}
            </MainSlider>
          ) : null}
        </div>
      </div>
    </div>
  );
};
