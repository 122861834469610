import React, { useCallback, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from 'swiper/modules';
import { Tab } from './Tab';
import type { Swiper as TSwiper } from 'swiper/types';
import clsx from 'clsx';
import { EGalleryTheme } from '../Gallery';

type TTabsSliderProps = {
  tabs?: {
    title?: React.JSX.Element | string;
    description?: React.JSX.Element | string;
  }[];
  onSwiper?: (swiper: TSwiper) => void;
  className?: string;
  theme: EGalleryTheme;
  wrapperClass?: string;
  hasNowrap?: boolean;
  gradientColorFrom?: string;
  gradientColorTo?: string;
};

export const TabsSlider: React.FC<TTabsSliderProps> = ({
  tabs = [],
  onSwiper,
  className,
  wrapperClass,
  theme = EGalleryTheme.Theme1,
  hasNowrap = true,
  gradientColorFrom = '#FFFFFF00',
  gradientColorTo = '#FFFFFF',
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [isFirsSlideFullyVisible, setIsFirsSlideFullyVisible] = useState(true);
  const [isLastSlideFullyVisible, setIsLastSlideFullyVisible] = useState(false);
  const handleSwiperUpdate = useCallback((swiper: TSwiper) => {
    setActiveIndex(swiper?.clickedIndex);
    setIsFirsSlideFullyVisible(
      !!swiper.slides[0]?.classList?.contains('swiper-slide-fully-visible'),
    );
    setIsLastSlideFullyVisible(
      !!swiper.slides[swiper.slides.length - 2]?.classList?.contains(
        'swiper-slide-fully-visible',
      ),
    );
  }, []);
  return (
    <>
      <div className={clsx(wrapperClass, 'relative')}>
        <Swiper
          spaceBetween={32}
          slidesPerView={'auto'}
          onSwiper={onSwiper}
          onUpdate={handleSwiperUpdate}
          className={clsx(className, 'relative')}
          direction={'horizontal'}
          modules={[Thumbs]}
          breakpoints={{
            688: {
              slidesPerView: 'auto',
              spaceBetween: theme === EGalleryTheme.Theme1 ? 64 : 16,
              direction:
                theme === EGalleryTheme.Theme1 ? 'horizontal' : 'vertical',
            },
          }}
        >
          {tabs?.map(({ title }, index) => (
            <SwiperSlide
              key={index}
              className='h-auto w-fit cursor-pointer ltr:!ml-0 rtl:!mr-0'
            >
              <Tab
                className={clsx({
                  'tablet:whitespace-normal': theme === EGalleryTheme.Theme2,
                  'whitespace-nowrap': hasNowrap,
                })}
                isActive={index === activeIndex}
                title={title}
              />
            </SwiperSlide>
          ))}
          <SwiperSlide
            className={clsx(
              '-ms-8 w-px',
              theme === EGalleryTheme.Theme1
                ? 'tablet:-ms-16'
                : 'tablet:hidden',
            )}
          />
        </Swiper>
        <div
          className={clsx(
            'pointer-events-none absolute end-0 top-0 z-10 h-full w-[45px] rtl:-scale-x-100',
            {
              'tablet:w-[90px]': theme === EGalleryTheme.Theme1,
              'tablet:hidden': theme === EGalleryTheme.Theme2,
              'end-[unset] start-0 -scale-x-100 rtl:scale-x-100':
                isLastSlideFullyVisible && !isFirsSlideFullyVisible,
              '!hidden': isFirsSlideFullyVisible && isLastSlideFullyVisible,
            },
          )}
          style={{
            background: `linear-gradient(90deg, ${gradientColorFrom} 0%, ${gradientColorTo} 100%)`,
          }}
        ></div>
      </div>
      {theme === EGalleryTheme.Theme2 &&
      activeIndex !== -1 &&
      tabs[activeIndex]?.description ? (
        <div
          className={clsx('mt-auto', {
            'hidden text-sm font-medium leading-[150%] tablet:block':
              theme === EGalleryTheme.Theme2,
          })}
        >
          {tabs[activeIndex].description}
        </div>
      ) : null}
    </>
  );
};
