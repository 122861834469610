import React from 'react';
import clsx from 'clsx';

export type TTabProps = {
  title?: React.JSX.Element | string;
  isActive?: boolean;
  className?: string;
};

export const Tab: React.FC<TTabProps> = ({
  title,
  isActive = false,
  className,
}) => {
  return (
    <div
      className={clsx(
        'flex h-full flex-col gap-[14px] overflow-hidden text-base font-semibold leading-extra-loose text-interface-400 hover:text-interface-700 tablet:text-xl',
        { '!text-interface-1000': isActive },
        className,
      )}
    >
      {title}
      <div
        data-active={isActive || undefined}
        className={clsx(
          'mt-auto h-0.5 w-[200%] bg-[linear-gradient(to_right,_#121212_50%,_#EBEBEE_50%)] transition-transform ease-linear rtl:-scale-x-100',
          {
            '-translate-x-1/2 duration-0 rtl:translate-x-1/2': !isActive,
            'translate-x-0 duration-[5000ms]': isActive,
          },
        )}
      ></div>
    </div>
  );
};
