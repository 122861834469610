import React, { ReactNode } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Thumbs, EffectFade } from 'swiper/modules';
import { ImageProps } from 'next/image';
import type { Swiper as TSwiper } from 'swiper/types';
import clsx from 'clsx';
import { EGalleryTheme } from '../Gallery';
import { ImageWithPlaceholder } from '@front/shared/ds';

type TMainSliderProps = {
  tabs?: {
    description?: React.JSX.Element | string;
    imageDesktop?: ImageProps;
    imageTablet?: ImageProps;
    imageMobile?: ImageProps;
  }[];
  thumbsSwiper?: TSwiper;
  theme: EGalleryTheme;
  onSwiper?: (swiper: TSwiper) => void;
  children?: ReactNode;
};

export const MainSlider: React.FC<TMainSliderProps> = ({
  onSwiper,
  tabs,
  thumbsSwiper,
  theme = EGalleryTheme.Theme1,
  children,
}) => {
  const handleActiveIndexChange = (swiper: TSwiper) => {
    if (Array.isArray(swiper.thumbs?.swiper?.slides)) {
      swiper.thumbs.swiper.clickedIndex = swiper.realIndex;
      swiper.thumbs.swiper.slideTo(swiper.realIndex);
      swiper.thumbs.swiper.slides.forEach(slide =>
        slide.classList.remove('swiper-slide-thumb-active'),
      );
      swiper.thumbs.swiper.update();
    }
  };
  const handleDestroy = (swiper: TSwiper) => {
    swiper.thumbs?.swiper?.off('tap');
  };
  const imageClass =
    'absolute start-0 top-0 h-full w-auto max-w-none rtl:end-0 rtl:start-[unset]';
  return (
    <Swiper
      effect='fade'
      modules={[Thumbs, Autoplay, EffectFade]}
      onSwiper={swiper => {
        if (onSwiper) {
          onSwiper(swiper);
        }
        swiper.autoplay?.stop();
      }}
      onDestroy={handleDestroy}
      thumbs={
        thumbsSwiper
          ? {
              swiper: thumbsSwiper,
              multipleActiveThumbs: false,
            }
          : undefined
      }
      spaceBetween={10}
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      onActiveIndexChange={handleActiveIndexChange}
      loop={true}
      speed={500}
      loopPreventsSliding={false}
      fadeEffect={{
        crossFade: true,
      }}
    >
      {children
        ? children
        : tabs?.map(
            (
              { description, imageDesktop, imageTablet, imageMobile },
              index,
            ) => (
              <SwiperSlide
                key={index}
                className={clsx({
                  'relative h-[35rem] overflow-hidden rounded-[2rem] p-6 tablet:h-[37.5rem] tablet:p-10 desktop:p-16':
                    theme === EGalleryTheme.Theme1,
                })}
              >
                {description ? (
                  <div
                    className={clsx('relative z-10', {
                      'max-w-[34.5rem] text-3xl font-semibold leading-loose tablet:text-6xl tablet:leading-relaxed':
                        theme === EGalleryTheme.Theme1,
                      'mb-6 max-w-[12.75rem] text-sm font-medium leading-[150%] tablet:hidden':
                        theme === EGalleryTheme.Theme2,
                    })}
                  >
                    {description}
                  </div>
                ) : null}
                {imageDesktop ? (
                  <ImageWithPlaceholder
                    className={clsx(
                      {
                        'hidden desktop:block': theme === EGalleryTheme.Theme1,
                        'max-h-[36.25rem] rounded-[2rem]':
                          theme === EGalleryTheme.Theme2,
                      },
                      theme === EGalleryTheme.Theme1 ? imageClass : '',
                    )}
                    {...imageDesktop}
                  />
                ) : null}
                {imageTablet && theme === EGalleryTheme.Theme1 ? (
                  <ImageWithPlaceholder
                    className={clsx(
                      imageClass,
                      'hidden tablet:block desktop:hidden',
                    )}
                    {...imageTablet}
                  />
                ) : null}
                {imageMobile && theme === EGalleryTheme.Theme1 ? (
                  <ImageWithPlaceholder
                    className={clsx(imageClass, 'tablet:hidden')}
                    {...imageMobile}
                  />
                ) : null}
              </SwiperSlide>
            ),
          )}
    </Swiper>
  );
};
