import React from 'react';
import { ReactComponent as IconArrowRight } from '../icons/icon-arrow-right.svg';
import {
  Button,
  EButtonSize,
  EButtonTheme,
  EButtonVariant,
} from '../../../atoms/Button';
import type { Swiper as TSwiper } from 'swiper/types';
import clsx from 'clsx';

type TNavigationProps = {
  className?: string;
  swiper?: TSwiper;
  buttonClass?: string;
};

export const Navigation: React.FC<TNavigationProps> = ({
  className,
  swiper,
  buttonClass,
}) => {
  return (
    <div className={clsx('flex gap-2', className)}>
      <Button
        variant={EButtonVariant.ContainedRounded}
        theme={EButtonTheme.Secondary}
        size={EButtonSize.Large}
        className={clsx('min-w-16 px-5', buttonClass)}
        onClick={() => swiper?.slidePrev()}
      >
        <IconArrowRight className='rtl:-scale-x-100' />
      </Button>
      <Button
        variant={EButtonVariant.ContainedRounded}
        theme={EButtonTheme.Secondary}
        size={EButtonSize.Large}
        className={clsx('min-w-16 px-5', buttonClass)}
        onClick={() => {
          swiper?.slideNext();
        }}
      >
        <IconArrowRight className='-scale-x-100 rtl:scale-x-100' />
      </Button>
    </div>
  );
};
